import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { fetchPostJSON } from '../utils/api-helpers';
import Spinner from './Spinner';

export default function AddressField({ selectionChanged }: { selectionChanged: (location: any) => void }) {
  const router = useRouter();
  const [position, setPosition] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const [locations, setLocations] = useState<any[]>([]);
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  async function getAddress() {
    if (position) {
      setLoading(true);
      const response = await fetchPostJSON('/api/location/inverse', {
        ...position,
        language: router.locale,
      });

      if (response.statusCode === 500) {
        console.error(response.message);
        return;
      }

      if (response.results) {
        setLocations(response.results);
        if (query === '') {
          setSelected(response.results[0]);
        }
      }

      setLoading(false);
    }
  }

  async function searchAddress(value: string) {
    setLoading(true);
    const response = await fetchPostJSON('/api/location', {
      address: value,
      language: router.locale,
    });

    if (response.statusCode === 500) {
      console.error(response.message);
      return;
    }

    if (response.results) {
      setLocations(response.results);
    }

    setLoading(false);
  }

  function selectElement(addressResult) {
    setSelected(addressResult);
    if (addressResult?.geometry?.location) {
      selectionChanged(addressResult);
    }
  }

  useEffect(() => {
    if ('geolocation' in navigator) {
      // Retrieve latitude & longitude coordinates from `navigator.geolocation` Web API
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const { latitude, longitude } = coords;
        setPosition({ latitude, longitude });
      });
    }
  }, []);

  useEffect(() => {
    getAddress();
  }, [position]);

  useEffect(() => {
    if (query !== '') {
      searchAddress(query);
    }
  }, [query]);

  const { t } = useTranslation('common');

  return (
    <div className="z-50 relative">
      <Combobox value={selected} onChange={selectElement}>
        <div className="">
          <div className="h-14 flex flex-row items-center rounded-full bg-surface-container-highest text-on-surface p-2 shadow">
            <div className="relative w-full h-full">
              <ComboboxInput
                className="block w-full h-full pr-4 text-ellipsis ml-4 flex-1 overflow-hidden border-none bg-transparent text-lg outline-none focus:outline-none focus:ring-0 focus:border-none active:border-none"
                displayValue={(location: any) => location?.formatted_address ?? ''}
                placeholder={t('location_placeholder')}
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
              />
              <div className="absolute right-0 top-0 flex items-center h-full">
                <Spinner loading={loading} spinnerClasses="text-primary w-5 h-5" />
              </div>
            </div>
            <button
              type="submit"
              onClick={() => {
                if (selected) {
                  selectionChanged(selected);
                }
              }}
              className="whitespace-nowrap rounded-full bg-black px-6 py-2 text-lg font-semibold text-white transition-all hover:bg-teal-600/40 hover:text-black hover:ring-2 hover:ring-inset hover:ring-black"
            >
              {t('home:header.search')}
            </button>
          </div>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ComboboxOptions
              className={`mt-1 max-h-60 w-full overflow-auto rounded-2xl py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}
            >
              {locations.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-secondary text-sm">
                  {t('home:header.no_results')}
                </div>
              ) : (
                locations.map((location) => (
                  <ComboboxOption
                    key={location.place_id}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-primary text-on-primary' : ''}`
                    }
                    value={location}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {location.formatted_address}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ComboboxOption>
                ))
              )}
            </ComboboxOptions>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
